const permissions = {};

permissions.admin = ["angel.luiis@gmail.com"];
permissions.manager = [
  ...permissions.admin,
  "juanantoniocalderon32@gmail.com",
  "plataformamicroshopnet@gmail.com",
  "cristinahernandezgarcia78@gmail.com",
];
permissions.user = [
  ...permissions.admin,
  ...permissions.manager,
  "diegomicroshop@gmail.com",
  "javiermicroshop@gmail.com",
  "juancarlosmicroshop@gmail.com",
  "luisgalan67@gmail.com",
  "elenaolivaresmicroshop@gmail.com",
  "maitemicroshop@gmail.com",
  "belenmartinmicroshop@gmail.com",
];

export default function permissionsFn(role, email) {
  let isCoincident = false;

  for (let index = 0; index < permissions[role].length; index++) {
    if (permissions[role][index] === email) {
      isCoincident = true;
      break;
    }
  }

  return isCoincident;
}
